<template>
    <header-home ref="HeaderHomeRef"></header-home>
    <!--<button @click="methodeStore()">{{ count }}</button> -->
    <rechercher ref="RechercherRef"></rechercher>
    <coworking-results v-show="isShowResult" ref="CoworkingResultsRef"></coworking-results>
</template>

<script>
    import HeaderHome from "./HeaderHome";
    import Rechercher from "./Rechercher";
    import CoworkingResults from "./CoworkingResults";
    import { mapState } from 'vuex'
    import { mapGetters } from 'vuex'
    import { mapMutations } from 'vuex'
    import { mapActions } from 'vuex'

    export default {
        mounted() {
            //this.$store.state.count
            //console.log(this.$store.state.todos);
            //console.log(this.$store.getters.getTodoById(2));
            //this.$store.commit('increment')
        },
        computed: {
            //Ajout des getters du store
            ...mapGetters([
               'doneTodosCount',
               'getItemBasketCount',
               'getCount',
               // ...
            ]),
            ...mapState({
                maxBasketItem: state => state.maxBasketItem,
            })
        },
        components:{
            HeaderHome,
            Rechercher,
            CoworkingResults,
        },
        props: ['auth'],
        inject: ['$swal2'],
        data () {
            return {
                urlHome: APP_URL,
                isShowResult: false
            }
        },
        provide: function () {
            return {
                rechercher: this.rechercher,
                addToBasket: this.addToBasket,
            }
        },
        methods: {
            ...mapMutations([
                'addBasketItem'
            ]),
            ...mapActions([
            ]),
            //TODO faire un unique point d'entrée pour cette fonction qui est dupliquée dans bureau-de-coworking/Reserver-Date.vue
            addToBasket(item) {
                let HeaderHome = this.$refs.HeaderHomeRef;
                let initialCount = this.getItemBasketCount;

                if(initialCount == this.maxBasketItem) {
                    this.$swal2.fire({
                        title: "Erreur !",
                        text: "Le nombre maximum d'articles dans le panier a été atteint",
                        icon: "error",
                    })
                    return
                }

                this.addBasketItem(item);
                if(initialCount < this.getItemBasketCount) {
                    HeaderHome.openPopup('popup-basket')
                }
                else {
                    this.$swal2.fire({
                        title: "Erreur !",
                        text: "Le produit est déjà dans votre panier",
                        icon: "error",
                    })
                }
            },
            rechercher(date, duree, type) {
                let rechercher = this.$refs.RechercherRef;
                let coworkingResults = this.$refs.CoworkingResultsRef;

                coworkingResults.isLoading = true;
                this.isShowResult = true;
                axios.post(APP_URL + '/api/availability/check-date-availability', {
                    dateReservation: date,
                    dureeEspace: duree,
                    typeEspace: type,
                })
                .then(response => {
                    coworkingResults.results = response.data.availability;
                })
                .catch(error => {
                    //alert(error);
                    coworkingResults.results = {};
                })
                .finally(() =>  {
                    coworkingResults.isLoading = false;
                })
            },
            methodeStore() {
                //Exemple d'utilisations des states
                /*this.incrementAsync({
                    amount: 10
                })*/
                //Actions are triggered with the store.dispatch method:
                //this.$store.dispatch('increment')
                /*this.$store.dispatch('increment', {
                    amount: 10
                })*/
                //Call MapMutations function
                /*this.increment({
                    amount: 10
                })*/
                /*this.$store.commit('increment', {
                    amount: 10
                })*/
            }

        },
    }
</script>
