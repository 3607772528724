<template>
    <section id="header">
        <nav class="header-container">
            <div class="header-logo">
                <a :href="urlHome" class="header-a-logo">
                    <img class="header-img-logo" :src="srcLogo" alt="logo-le-verger">
                </a>
            </div>
            <ul id="navigation" class="navigation">
                <li class="header-li">
                    <a class="header-a" href="mailto:support@coworking-le-verger.com"><i class="fas fa-envelope fa-2x header-icon"></i>
                        <span class="header-li-a-text">Service Client</span></a>
                </li>
                <li class="header-li">
                    <a class="header-a" href="tel:+33130376646"><i class="fas fa-phone fa-2x header-icon"></i>
                        <span class="header-li-a-text">01 30 37 66 46</span>
                        <span class="header-li-a-text horaire">09h00 - 13h00</span></a>
                </li>
                <li v-if="userState == null" class="header-li">
                    <a class="header-a" :href="urlAccountSignin"><i class="fas fa-user-circle fa-2x header-icon"></i>
                        <span class="header-li-a-text">Mon compte</span></a>
                </li>
                <li v-else class="header-li li-mon-compte-auth">
                    <a @click="openPopup('popup-mon-compte')" id="a-popup-mon-compte" class="header-a" href="#"><i id="icon-popup-mon-compte" class="fas fa-user-circle fa-2x header-icon"></i>
                        <span class="li-a-account-desktop">{{ userState.prenom }}<i class="fas fa-caret-down caret-icon"></i></span><span class="li-a-account-mobile bold">{{ userState.prenom.substring(0, 1).toUpperCase() + userState.nom.substring(0, 1).toUpperCase() }}<i class="fas fa-caret-down caret-icon"></i></span></a>
                </li>
                <li class="header-li li-basket">
                    <div class="count-items-basket">{{ getItemBasketCount}}</div>
                    <a class="header-a" :href="urlCheckout" title="aller à la section 2" id="a-popup-basket"><i id="icon-popup-basket" class="fas fa-shopping-bag fa-2x header-icon"></i>
                        <span class="header-li-a-text">Panier</span></a>
                </li>
            </ul>
        </nav>
    </section>
    <div class="modal-background"></div>
    <div v-if="userState !== null">
        <div id="popup-mon-compte" class="modal popup-sous-menu">
            <div class="box-1">
                <p class="bold">{{ userState.prenom }} {{ userState.nom }}</p>
                <a @click="closePopup('popup-mon-compte')">
                    <i class="fas fa-times-circle fa-2x icon-circle-box-1"></i>
                </a>
            </div>
            <div class="box-2">
                <ul class="sous-menu">
                    <li><a :href="urlMesReservations">Mes réservations</a></li>
                    <div class="hr"></div>
                    <li>
                        <a href="#" @click="logout()">
                            Se déconnecter
                        </a>
                        <form id="logoutForm" :action="urlLogout" method="POST" class="d-none">
                            <input type="hidden" name="_token" :value="csrf">
                        </form>
                    </li>
                </ul>
            </div>
        </div>
    </div>
    <div id="popup-basket" class="modal popup-sous-menu popup-basket">
        <div class="popup-basket-container">
            <h2 class="popup-basket-title">Ajouté au panier !</h2>
            <p class="text-helper">Votre panier contient {{ getItemBasketCount }} article(s)</p>
            <div class="popup-basket-garanties-container">
                <div class="popup-basket-icons-group">
                    <i class="fas fa-check-square popup-basket-icon-color fa-lg"></i>
                    <p class="popup-basket-p-garanties bold">Annulation gratuite jusqu'à 24 heures avant</p>
                </div>
                <div class="popup-basket-icons-group">
                    <i class="fas fa-check-square popup-basket-icon-color fa-lg"></i>
                    <p class="popup-basket-p-garanties bold">Payez en ligne aujourd'hui</p>
                </div>
            </div>
            <div class="popup-basket-item-added-container">
                <div class="popup-basket-item-image">
                    <img class="basket-item-img" :src="getItemBasketCount > 0 ? urlPhotos + getLastItemsBasket.image.split(';')[0] : ''" alt="photos-bureau-salles-reu">
                </div>
                <div class="popup-basket-item-content">
                    <h3 class="popup-basket-item-title">{{getLastItemsBasket.nom}}</h3>
                    <div class="popup-basket-item-content-child">
                        <div class="popup-basket-item-content-intitule">
                            <p class="bold">Durée</p>
                            <p class="bold">Date début</p>
                            <p class="bold">Date fin</p>
                            <p class="bold">Horaire</p>
                            <p class="bold">Prix</p>
                        </div>
                        <div class="popup-basket-item-content-valeur">
                            <p>{{ getLastItemsBasket.duree }}</p>
                            <p>{{ $moment(getLastItemsBasket.dateDebut).format('DD/MM/YYYY') }}</p>
                            <p>{{ $moment(getLastItemsBasket.dateFin).format('DD/MM/YYYY') }}</p>
                            <p>{{ getLastItemsBasket.horaire }}</p>
                            <p class="popup-basket-price bold">{{ getLastItemsBasket.prix }}€</p>
                        </div>
                    </div>
                </div>
            </div>
            <div class="hr hr-popup"></div>
            <div class="popup-basket-montant-total-container">
                <p class="p-intitule-montant-total">Total du panier:</p>
                <p class="p-montant-total bold">{{ getBasketTotalPrice }}€</p>
            </div>
            <div class="hr hr-popup"></div>
            <br>
            <a class="btn btn-yellow btn-popup-basket" @click="closePopup(this.popupOpen)" >Continuer mes réservations</a>
            <a class="btn btn-green btn-popup-basket" :href="urlCheckout" >Voir mon panier</a>
            <br>
        </div>
    </div>
</template>

<script>
    import { mapMutations } from 'vuex'
    import { mapGetters } from 'vuex'
    import { mapState } from 'vuex'

    export default {
        inject: ['$moment'],
        mounted() {
            //console.log(this.getItemsBasket);
            //console.log(this.getItemsBasket[this.getItemsBasket.length -1]);
            document.addEventListener(
                "click",
                function(event) {
                    // If user either clicks X button OR clicks outside the modal window, then close modal by calling closeModal()
                    if (!event.target.closest(".modal")) {

                        if(this.popupOpen == 'popup-mon-compte') {
                            if (!event.target.closest(".li-mon-compte-auth")) {
                                this.closePopup(this.popupOpen)
                            }
                        }

                        if(this.popupOpen == 'popup-basket') {
                            if (!event.target.closest(".li-basket") && !event.target.closest(".btn-ajouter-panier")) {
                                this.closePopup(this.popupOpen)
                            }
                        }
                    }
                }.bind(this),
                false
            )
        },
        computed: {
            //Ajout des getters du store
            ...mapGetters([
                'getItemBasketCount',
                'getItemsBasket',
                'getLastItemsBasket',
                'getBasketTotalPrice'
            ]),
            ...mapState({
                //helper to found the variable count from the state
                userState: state => state.user,
            })
        },
        data () {
            return {
                popupOpen: '',
                urlHome: APP_URL,
                urlCheckout: APP_URL + '/checkout',
                urlMesReservations: APP_URL + '/mes-reservations',
                urlPhotos: APP_URL + '/assets/images/',
                srcLogo: APP_URL + '/assets/images/logo-le-verger.png',
                urlAccountSignin: APP_URL + '/account/signin',
                urlLogout: APP_URL + '/logout',
                csrf: document.head.querySelector('meta[name="csrf-token"]').content,
                mainPinkColor: getComputedStyle(document.documentElement).getPropertyValue('--main-pink-color'),
                mainGreenColor: getComputedStyle(document.documentElement).getPropertyValue('--main-green-color'),
                mainYellowColor: getComputedStyle(document.documentElement).getPropertyValue('--main-yellow-color'),
                mainWhiteColor: getComputedStyle(document.documentElement).getPropertyValue('--main-white-color')
            }
        },
        methods: {
            logout: function() {
                document.forms["logoutForm"].submit();
            },
            openPopup: function(id) {
                if(this.popupOpen) {
                    this.closePopup(this.popupOpen);
                }

                //window.scrollTo(0, 0);
                document.getElementById('navigation').scrollIntoView();
                document.getElementById(id).style.display = "block";
                document.querySelector('.modal-background').style.display = "block";
                document.getElementById('a-' + id).style.color = this.mainPinkColor
                document.getElementById('icon-' + id).style.color = this.mainPinkColor;
                this.popupOpen = id;

            },
            closePopup(id) {
                document.getElementById(id).style.display = "none";
                document.getElementById('a-' + id).style.color = this.mainGreenColor;
                document.getElementById('icon-' + id).style.color = this.mainGreenColor;
                document.querySelector('.modal-background').style.display = "none";
                this.popupOpen = '';
            }

        },
    }
</script>
