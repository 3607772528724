/**
 * First we will load all of this project's JavaScript dependencies which
 * includes Vue and other libraries. It is a great starting point when
 * building robust, powerful web applications using Vue and Laravel.
 */

require('./bootstrap');
import '@fortawesome/fontawesome-free/js/fontawesome'
import '@fortawesome/fontawesome-free/js/brands'
import '@fortawesome/fontawesome-free/js/solid'

import { createApp } from "vue";
import { store } from './store';
import VCalendar from 'v-calendar';
import { config } from './global-properties'
import ExampleComponent from "./components/ExampleComponent.vue";
import ParentHome from "./components/all/ParentHome";
import ParentCheckout from "./components/checkout/ParentCheckout";
import HeaderHome from "./components/all/HeaderHome";
import Signin from "./components/Signin";
import MesReservations from "./components/mes-reservations/MesReservations";
import ReserverDate from "./components/bureau-de-coworking/ReserverDate";
import EmptyBasket from "./components/checkout/EmptyBasket";
import SliderPhotos from "./components/all/SliderPhotos";
import moment from 'moment';
import Swal from 'sweetalert2';

//Vue.prototype.$laravel = Laravel

const app = createApp({
    components: {
        ExampleComponent,
        Signin,
        ParentHome,
        ParentCheckout,
        HeaderHome,
        ReserverDate,
        EmptyBasket,
        MesReservations,
        SliderPhotos
    },
    methods: {
    }
});

//app.config.globalProperties.$moment = moment;
app.config.globalProperties.$filters = config.globalProperties.filters()
app.config.globalProperties.$functions = config.globalProperties.functions()

//console.log(app.config)
app.provide('$moment', moment);

let swal2 = Swal.mixin({
    cancelButtonColor: 'var(--main-red-color)',
    confirmButtonColor: 'var(--main-green-color)',
    customClass: {
        loader: 'custom-loader-color'
    }
})

app.provide('$swal2', swal2);
app.use(VCalendar, {});
app.use(store);


//Données de l'utilisateur
if(typeof userGlobal !== 'undefined') {
    store.commit('setAuthUser', userGlobal);
}

app.mount("#app");

