<template>
    <div class="account-box-container">
        <div id="forget-password-confirmation">
            <div class="account-box-one">
                <div class="text-center">
                    <i class="fas fa-paper-plane fa-3x icon-send-fpc"></i>
                </div>
                <p class="text-center bold">Nous venons de vous envoyer un email à :
                    {{ this.$parent.emailForget }}</p>
                <p>Celui-ci contient un lien permettant de réinitaliser votre mot de passe</p>
                <p class="bold">Vous n'avez pas reçu de mail ?</p>
                <p>Vérifiez bien l'orthographe de l'adresse saisie, ou que le mail n’est pas dans vos indésirables.</p>
                <button @click="showParentComponent('se-connecter')" class="btn btn-pink btn-account">
                    Retour
                </button>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        mounted() {
            console.log('PasswordForget mounted.')
        },
        props: ['email'],
        data () {
            return {
                action: APP_URL + '/password/email',
            }
        },
        methods: {
            showParentComponent: function (component) {
                if(typeof this.$parent.showComponent !== 'undefined' && typeof this.$parent.showComponent == 'function') {
                    this.$parent.showComponent(component)
                }
            },
        },
    }
</script>
