<template>
    <div class="account-box-container" >
        <div id="password-reset">
            <div class="account-box-one">
                <h1 class="account-h1">Créer un nouveau mot de passe</h1>
                <div class="container-errors" v-if="errors.length">
                    <li v-for="error in errors">
                        {{ error.value }}
                    </li>
                </div>
                <form method="POST">
                    <label for="password" class="account-label">Mot de passe</label>
                    <input type="password" id="password" name="password" v-model="password" class="account-input">
                    <label for="password" class="account-label">Confirmer mot de passe</label>
                    <input type="password" id="password_confirmation" name="password_confirmation" v-model="passwordConfirmation" class="account-input">
                    <button @click.prevent="resetPassword" class="btn btn-pink btn-account">
                        Valider
                    </button>
                </form>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        mounted() {
            console.log('Se Connecter mounted.')

        },
        props: [],
        data () {
            return {
                action: APP_URL + '/password/reset',
                email: this.$parent.emailReset,
                token: this.$parent.tokenReset,
                password: '',
                passwordConfirmation: '',
                errors: []
            }
        },
        methods: {
            showParentComponent: function (component) {
                if(typeof this.$parent.showComponent !== 'undefined' && typeof this.$parent.showComponent == 'function') {
                    this.$parent.showComponent(component)
                }
            },
            resetPassword: function() {
                axios.post(this.action, {
                    token: this.token,
                    email: this.email,
                    password: this.password,
                    password_confirmation : this.passwordConfirmation

                    })
                    .then(response => {
                        window.location.href= response.request.responseURL
                    })
                    .catch(error => {
                        console.log(error)
                        this.errors = [];
                        if( error.response ) {
                            console.log(error.response)
                            for (const [key, value] of Object.entries(error.response.data.errors)) {
                                this.errors.push( {
                                    cle: key,
                                    value: value[0]
                                })
                            }
                        }
                    })
                    .finally(() =>  {
                        this.$parent.getNewToken();
                    })
            }

        },
    }
</script>
