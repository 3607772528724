<template>
    <div class="resume">
        <h1>En résumé</h1>
        <div class="resume-container box-shadow">
            <div class="montant-container">
                <p class="bold">Total:  </p>
                <p class="bold montant-price-total">{{ basketTotalPrice }}€</p>
            </div>
            <div class="hr"></div>
            <div class="resume-buttons">
                <a class="btn btn-yellow btn-montant-basket" :href="urlHome">Continuer mes réservations</a>
                <button v-if="basket.length && !getDisabledValid()" class="btn btn-green btn-montant-basket" :disabled="getDisabledValid()" @click.prevent="nextStep()">{{ getButtonValiderText }}</button>
                <button v-if="basket.length && getDisabledValid()" class="btn btn-green btn-montant-basket"><img class="btn-resume-icon" :src="urlIconLoading">{{ getButtonValiderText }}</button>
            </div>
        </div>
        <div class="garanties-container box-shadow">
            <div class="garanties-element">
                <i class="fas fa-lock fa-lg icons-garanties"></i>
                <p class="p-garantie-inititule bold">Paiement sécurisé</p>
            </div>
            <div class="hr"></div>
            <div class="garanties-element">
                <i class="fas fa-money-check-alt fa-lg icons-garanties"></i>
                <p class="p-garantie-inititule bold">Aucun frais de réservation</p>
            </div>
            <div class="hr"></div>
            <div class="garanties-element">
                <i class="fas fa-redo fa-lg icons-garanties"></i>
                <p class="p-garantie-inititule bold">Annulation 24h</p>
            </div>
        </div>
    </div>
</template>

<script>
import {mapGetters, mapState, mapMutations, mapActions} from 'vuex'

export default {
    inject: [ 'nextStep', 'getCurrentStep', 'getDisabledValid'],
    mounted() {
    },
    computed: {
        //Ajout des getters du store
        ...mapGetters([
        ]),
        ...mapState({
            //helper to found the variable count from the state
            basket: state => state.basket,
            basketTotalPrice : state => state.basketTotalPrice
        }),
        getButtonValiderText() {
            switch (this.getCurrentStep()) {
                case 1:
                    return 'Finaliser la commande';
                    break;
                case 3:
                    return 'Payer';
                default:
                    return 'Finaliser la commande';

            }
        }
    },
    components:{
    },
    props: ['auth'],
    data () {
        return {
            urlHome: APP_URL,
            urlIconLoading: APP_URL + '/assets/icons/loading-btn-white.png'
        }
    },
    methods: {
        ...mapMutations([
        ]),

    },
}
</script>