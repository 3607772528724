<template>
    <section id="commande-informations">
        <div class="commande-informations-container">
            <div class="formulaire-commande">
                <h1>Informations sur le client</h1>
                <div class="form-container box-shadow">
                    <div class="form-group-one">
                        <label for="societe">Société</label>
                        <input type="societe" maxlength="70" name="societe" id="societe" v-model="userFacturation.societe" @input="localUpdateUserFacturation" class="site-input">
                    </div>
                    <div class="form-group-two first-input">
                        <label for="name">Nom<sup>*</sup></label>
                        <input type="text" id="name" name="name" v-model="userFacturation.nom" @input="localUpdateUserFacturation"
                               class="site-input" maxlength="50" :class="{ 'is-invalid': hasError.nom }">
                        <div v-show="hasError.nom" class="invalid-feedback">
                            Nom invalide.
                        </div>
                    </div>
                    <div class="form-group-two second-input">
                        <label for="prenom">Prénom<sup>*</sup></label>
                        <input type="text" id="prenom" maxlength="50" name="prenom" v-model="userFacturation.prenom" @input="localUpdateUserFacturation"
                               class="site-input" :class="{ 'is-invalid': hasError.prenom }">
                        <div v-show="hasError.prenom" class="invalid-feedback">
                            Prénom invalide.
                        </div>
                    </div>
                    <div class="form-group-two first-input">
                        <label for="email">Adresse e-mail<sup>*</sup></label>
                        <input type="text" id="email" name="email" v-model="userFacturation.email"
                               class="site-input" disabled>
                    </div>
                    <div class="form-group-two second-input">
                        <label for="telephone">Téléphone<sup>*</sup></label>
                        <input type="tel" maxlength="13" id="telephone" name="telephone" v-model="userFacturation.telephone" @input="localUpdateUserFacturation"
                               class="site-input" :class="{ 'is-invalid': hasError.telephone }">
                        <div v-show="hasError.telephone" class="invalid-feedback">
                            Téléphone Invalide.
                        </div>
                    </div>
                    <div class="form-group-one">
                        <label for="adresse">Adresse<sup>*</sup></label>
                        <input type="adresse" maxlength="255" name="adresse" id="adresse" v-model="userFacturation.adresse" @input="localUpdateUserFacturation"
                               class="site-input" :class="{ 'is-invalid': hasError.adresse }">
                        <div v-show="hasError.adresse" class="invalid-feedback">
                            Adresse invalide.
                        </div>
                    </div>
                    <div class="form-group-two first-input">
                        <label for="cp">CP<sup>*</sup></label>
                        <input type="text" maxlength="5" id="cp" name="cp" v-model="userFacturation.cp" @input="localUpdateUserFacturation"
                               class="site-input" :class="{ 'is-invalid': hasError.cp }">
                        <div v-show="hasError.cp" class="invalid-feedback">
                            CP invalide.
                        </div>
                    </div>
                    <div class="form-group-two second-input">
                        <label for="ville">Ville<sup>*</sup></label>
                        <input type="text" maxlength="255" id="ville" name="ville" v-model="userFacturation.ville" @input="localUpdateUserFacturation"
                               class="site-input" :class="{ 'is-invalid': hasError.ville }">
                        <div v-show="hasError.ville" class="invalid-feedback">
                            Ville invalide.
                        </div>
                    </div>
                </div>
            </div>
            <resume-checkout></resume-checkout>
        </div>
    </section>
</template>

<script>
import {mapGetters, mapState, mapMutations, mapActions} from 'vuex';
import ResumeCheckout from "./ResumeCheckout";

export default {
    inject: ['$moment', 'nextStep', 'getCurrentStep'],
    mounted() {
    },
    computed: {
        //Ajout des getters du store
        ...mapGetters([
            'getItemBasketCount',
            // ...
        ]),
        ...mapState({
            //helper to found the variable count from the state
            basket: state => state.basket,
            basketTotalPrice : state => state.basketTotalPrice,
            userFacturation: state => state.userFacturation
        })
    },
    components:{
        ResumeCheckout,
    },
    props: ['auth'],
    data () {
        return {
            urlHome: APP_URL,
            hasError: {
                societe: false,
                nom: false,
                prenom: false,
                email: false,
                adresse: false,
                cp: false,
                ville: false,
                telephone: false,
            }
        }
    },
    methods: {
        ...mapMutations([
            'updateMessage',
            'updateUserFacturation'
        ]),
        localUpdateUserFacturation (e) {
            this.updateUserFacturation({
                value: e.target.value,
                name: e.target.name
            });
        },
        isDataFormValid: function () {
            Object.keys( this.hasError).map((key) => {this.hasError[key] = false});
            let valid = true;

            if (this.$functions.isEmpty(this.userFacturation.nom)) {
                this.hasError.nom = true;
                valid = false;
            }

            if (this.$functions.isEmpty(this.userFacturation.prenom)) {
                this.hasError.prenom = true;
                valid = false;
            }

            if (this.$functions.isEmpty(this.userFacturation.adresse)) {
                this.hasError.adresse = true;
                valid = false;
            }

            if (!this.$functions.isValidCP(this.userFacturation.cp)) {
                this.hasError.cp = true;
                valid = false;
            }

            if (!this.$functions.isValidTelephone(this.userFacturation.telephone)) {
                this.hasError.telephone = true;
                valid = false;
            }

            if (this.$functions.isEmpty( this.userFacturation.ville)) {
                this.hasError.ville = true;
                valid = false;
            }

            return valid;
        }
    },
}
</script>