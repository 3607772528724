<template>
    <section id="rechercher">
        <div class="rechercher-margin">
            <h1 class="text-white"> Trouvez un bureau, une salle de réunion et bien plus encore</h1>
            <div id="rechercher-container">
                <div class="rechercher-form-container">
                    <div id="rechercher-forms">
                        <div class="rechercher-tabs-container">
                            <div @click="setTabActive(1)" id="tabs-bureau" class="tabs-bureau" :class="{ 'tabs-active': typeEspaceSelected == 1 }">
                                <p class="rechercher-p-tabs-bureau" :class="{ 'p-tabs-active': typeEspaceSelected == 1 }"> Coworking</p>
                            </div>
                            <div @click="setTabActive(2)" id="tabs-salle-reunion" class="tabs-salle-reunion" :class="{ 'tabs-active': typeEspaceSelected == 2 }">
                                <p class="rechercher-p-tabs-sr" :class="{ 'p-tabs-active': typeEspaceSelected == 2 }"> Salle de reunion</p>
                            </div>
                        </div>
                        <v-date-picker class="d-inline rechercher-dp" :model-config="modelConfig"
                                       :masks="masks" v-model="sdate" color="pink" :min-date="$moment().add(1, 'days').format('YYYY-MM-DD')"
                                       :disabled-dates='{ weekdays: [1, 7] }' is-required>
                            <template v-slot="{ inputValue, inputEvents }">
                                <i class="fas fa-calendar icon-rechercher fa-lg"></i>
                                <input
                                    placeholder="Date"
                                    id="date"
                                    class="rechercher-date"
                                    :value="$moment(sdate).format('DD/MM/YYYY')"
                                    v-on="inputEvents"
                                    readonly
                                />
                            </template>
                        </v-date-picker>
                        <select @change="updateDateValue()" v-model="dureeEspaceSelected" name="periodeBureau" id="periodeBureau" class="rechercher-periode">
                            <option v-for="option in typeEspaceSelected==1 ? dureeEspaceBureau: dureeEspaceSR" :value="option.value">
                                {{ option.text }}
                            </option>
                        </select>
                        <a @click="rechercher(selectedDate, dureeEspaceSelected, typeEspaceSelected)" class="btn btn-yellow btn-rechercher"><div class="va-middle">Rechercher</div></a>
                    </div>
                </div>
                <div v-if="dureeEspaceSelected == 5">
                    <p class="rechercher-p-helptext-duree">
                        Les réservations au mois ne sont possibles que pour le premier du mois.
                    </p>
                </div>
            </div>
        </div>
    </section>
</template>

<script>
    export default {
        inject: ['rechercher', '$moment'],
        computed: {
            sdate: {
                get: function () {
                    //Pas de date au lancement de la page
                    if(this.selectedDate === null) {
                        let date = this.$moment();
                        let dayOfWeek = date.day();

                        //Vendredi
                        if (dayOfWeek === 5)
                            date.add(3, 'days');
                        //samedi
                        else if (dayOfWeek === 6)
                            date.add(2, 'days');
                        //dimanche (+1 jour et semaine +1 jour )
                        else {
                            //Date par défaut le lendemain
                            date.add(1, 'days');
                        }

                        this.selectedDate = date.format('YYYY-MM-DD');
                    }

                    return this.selectedDate;
                },
                set: function (newValue) {
                    let date = this.$moment(newValue);
                    let day = date.get('date');

                    if(day !== 1 && this.dureeEspaceSelected === 5) {
                        date.add(1, 'months');
                        date.set('date', 1);
                    }

                    this.selectedDate = date.format('YYYY-MM-DD');
                    this.reloadData();
                }
            }

        },
        created: function () {
        },
        mounted() {
            //this.rechercher(this.sdate, this.dureeEspaceSelected, this.typeEspaceSelected)
        },
        data () {
            return {
                urlHome: APP_URL,
                csrf: document.head.querySelector('meta[name="csrf-token"]').content,
                typeEspaceSelected: 1,
                dureeEspaceSelected: 1,
                selectedDate: null,
                modelConfig: {
                    type: 'string',
                    //masque de la variable vue
                    mask: 'YYYY-MM-DD', // Uses 'iso' if missing
                },
                masks: {
                    //à l'affichage
                    input: 'DD/MM/YYYY',
                },
                dureeEspaceBureau: [
                    { text: 'Matin', value: 1 },
                    { text: 'Après midi', value: 2 },
                    { text: 'Journée', value: 3 },
                    { text: 'Semaine', value: 4 },
                    { text: 'Mois', value: 5 },
                ],
                dureeEspaceSR: [
                    { text: 'Matin', value: 1 },
                    { text: 'Après midi', value: 2 },
                ],
            }
        },
        methods: {
            setTabActive: function (idTypeEspace) {
                this.typeEspaceSelected = idTypeEspace;
                this.dureeEspaceSelected = 1;
                this.reloadData();
            },
            updateDateValue() {
                this.sdate = this.selectedDate;
            },
            reloadData() {
                if(this.selectedDate && this.dureeEspaceSelected) {
                    this.rechercher(this.selectedDate, this.dureeEspaceSelected, this.typeEspaceSelected);
                }
            }


        },
    }
</script>
