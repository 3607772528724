<style>
    .swiper {
        width: 100%;
    }

    .swiper-slide {
        background-position: center;
        background-size: cover;
    }

    .swiper-slide img {
        display: block;
        width: 100%;
        height: 55vh;
    }

    .swiper-button-next, .swiper-button-prev {
        color: var(--main-pink-color);
    }

    .swiper-pagination-bullet-active {
        opacity: var(--main-yellow-color, 1);
        background: var(--main-yellow-color);
    }
</style>
<template>
    <swiper :loop="true" :effect="'fade'" :navigation="true" :pagination='{"clickable": true}' class="mySwiper">
        <template v-for="images in imagesArr">
            <swiper-slide>
                <img :src="urlRacine + '/assets/images/' +  images" :alt="basename(images)">
            </swiper-slide>
        </template>
    </swiper>
</template>

<script>
console.log(APP_URL)
// Import Swiper Vue.js components
import { Swiper, SwiperSlide } from 'swiper/vue';

// Import Swiper styles
import 'swiper/css';

import "swiper/css/effect-fade"
import "swiper/css/navigation"
import "swiper/css/pagination"

import SwiperCore, {
    EffectFade,Navigation,Pagination
} from 'swiper';

// install Swiper modules
SwiperCore.use([EffectFade,Navigation,Pagination]);

export default {
    props: ['images'],
    components: {
        Swiper,
        SwiperSlide,
    },
    setup(props) {
        const imagesArr= props.images.split(';');
        const urlRacine= APP_URL;

        return {
            imagesArr,
            urlRacine
        };
    },
    methods: {
        basename(path) {
            return path.split('/').reverse()[0];
        },
    }
};
</script>
