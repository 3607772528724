<template>
    <section id="panier-checkout">
        <div class="panier-chekout-container">
            <div class="pannier">
                <h1>Dans mon panier</h1>
                <p class="bold">Réservations en cours ({{getItemBasketCount}})</p>
                <div v-for="(item, index) in basket">
                    <div class="item-container box-shadow">
                        <div class="pannier-image">
                            <img class="panier-img" :src="urlPhotos + item.image.split(';')[0]" alt="photos-bureau-salles-reu">
                         </div>
                        <div class="pannier-decription">
                            <h3>{{item.nom}}</h3>
                            <div class="panier-description-line-container">
                                <p class="bold">Date:</p>
                                <p>Du {{ $moment(item.dateDebut).format('DD/MM/YYYY') }} au {{ $moment(item.dateFin).format('DD/MM/YYYY') }}</p>
                            </div>
                            <div class="panier-description-line-container">
                                <p class="bold">Durée:</p>
                                <p>{{ item.duree }}</p>
                            </div>
                            <div class="panier-description-line-container">
                                <p class="bold">Horaire:</p>
                                <p>{{ item.horaire }}</p>
                            </div>
                            <div class="panier-description-line-container">
                                <p class="bold">Prix:</p>
                                <p class="p-panier-prix bold">{{ item.prix }}€</p>
                            </div>
                            <div class="text-center">
                                <a class="btn btn-yellow btn-delete-panier" @click="deleteBasketItem(index)">
                                    <i class="fas fa-trash fa-sm pannier-icon-delete"></i>
                                    &nbsp;Supprimer
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <resume-checkout></resume-checkout>
        </div>
    </section>
</template>

<script>
import {mapGetters, mapState, mapMutations, mapActions} from 'vuex';
import ResumeCheckout from "./ResumeCheckout";

    export default {
        inject: ['$moment', 'nextStep', 'getCurrentStep', '$swal2'],

        mounted() {

        },
        computed: {
            //Ajout des getters du store
            ...mapGetters([
                'getItemBasketCount',
                // ...
            ]),
            ...mapState({
                //helper to found the variable count from the state
                basket: state => state.basket,
                basketTotalPrice : state => state.basketTotalPrice
            })
        },
        components:{
            ResumeCheckout,
        },
        props: ['auth'],
        data () {
            return {
                urlHome: APP_URL,
                urlPhotos: APP_URL + '/assets/images/',
            }
        },
        methods: {
            ...mapMutations([
                'deleteBasketItem'
            ]),

        },
    }
</script>
