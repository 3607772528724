import { createStore } from 'vuex'
import createPersistedState from "vuex-persistedstate";

// Create a new store instance.
export const store = createStore({
    state: {
        user: null,
        userFacturation: null,
        basket: [],
        basketTotalPrice: 0,
        maxBasketItem: 10,
    },
    mutations: {
        setAuthUser(state, user) {
            //Déconnexion
            state.user = null;
            //Connection de l'utilisateur
            if(user) {
                state.user = {
                    id: user.id,
                    nom: user.nom,
                    prenom: user.prenom,
                    role: user.role,
                    email: user.email,
                    telephone: user.telephone
                }

                //On renseigne les données de facturation si pas existantes ou si nouveau utilisateur connecté
                if(!state.userFacturation ||  user.id != state.userFacturation.userId) {
                    state.userFacturation = {
                        userId: user.id,
                        societe: '',
                        nom: user.nom,
                        prenom: user.prenom,
                        email: user.email,
                        adresse: '',
                        cp: '',
                        ville: '',
                        telephone: user.telephone
                    }
                }

            }
        },
        updateUserFacturation (state, {value, name}) {
            state.userFacturation[name] = value
        },
        addBasketItem(state, item) {
            let initialCount = state.basket.length;

            state.basket.push({
                idBureau: item.id,
                image: item.image,
                duree: item.duree,
                horaire: item.horaire,
                prix: item.prix,
                nom: item.nom,
                dateDebut: item.date_debut,
                dateFin: item.date_fin,
                dureeEspaceId : item.duree_espace_id,
                typeEspaceId : item.type_espace_id,
            });

            var dataArr = state.basket.map(item=>{
                return [JSON.stringify(item),item]
            }); // creates array of array

            var maparr = new Map(dataArr); // create key value pair from array of array
            state.basket = [...maparr.values()];//converting back to array from mapobject

            //Ajout d'un article au panier sans doublon
            if(initialCount < state.basket.length) {
                state.basketTotalPrice += item.prix;
            }
        },
        deleteBasketItem(state, index) {
            if(typeof state.basket[index] !== 'undefined' && state.basket[index] !== null) {
                state.basketTotalPrice -= state.basket[index].prix;
                state.basket.splice(index, 1)
            }
        },
        emptyBasket(state) {
            state.basket = []
            state.basketTotalPrice = 0;
        },
    },
    //Actions can contain arbitrary asynchronous operations
    actions: {
    },
    getters: {
        isLoggedIn(state) {
            return state.user !== null;
        },
        getItemBasketCount(state) {
            return state.basket.length
        },
        getItemsBasket(state) {
            return state.basket
        },
        getLastItemsBasket(state) {
            return state.basket.length ? state.basket[state.basket.length -1] : []
        },
        getBasketTotalPrice(state) {
            return state.basketTotalPrice
        },
    },
    plugins: [createPersistedState({
        key: 'app',
        storage: window.localStorage,
    })],
})
