<template>
    <div class="account-box-container" >
        <div id="se-connecter">
            <div class="account-box-one">
                <h1 class="account-h1">Déjà client ?</h1>
                <div class="container-errors" v-if="errors.length">
                    <li v-for="error in errors">
                        {{ error.value }}
                    </li>
                </div>
                <form method="POST">
                    <label for="email" class="account-label">Adresse e-mail</label>
                    <input type="text" id="email" name="email" v-model="email" class="account-input">
                    <label for="password" class="account-label">Mot de passe</label>
                    <input type="password" id="password" name="password" v-model="password" class="account-input">
                    <a href="#" @click="showParentComponent('password-forget')" class="signin-a-password-forget">
                        <p class="signin-p-password-forget bold">
                            mot de passe oublié ?
                        </p>
                    </a>
                    <button @click.prevent="seConnecter" class="btn btn-pink btn-account">
                        Se connecter
                    </button>
                </form>
            </div>
            <div class="account-box-two">
                <h1 class="account-h1">Nouveau client ?</h1>
                <a @click="showParentComponent('sinscrire')" class="btn btn-pink btn-account">Créer un compte</a>
                <p class="text-helper text-center">En créant ou en vous connectant à un compte, vous acceptez nos
                    conditions générales et notre charte de confidentialité.</p>
            </div>
        </div>
    </div>
</template>

<script>
    import { mapMutations } from 'vuex'

    export default {
        mounted() {
            console.log('Se Connecter mounted.')

        },
        props: [],
        data () {
            return {
                action: APP_URL + '/login',
                email: '',
                password: '',
                errors: []
            }
        },
        methods: {
            showParentComponent: function (component) {
                if(typeof this.$parent.showComponent !== 'undefined' && typeof this.$parent.showComponent == 'function') {
                    this.$parent.showComponent(component)
                }
            },
            seConnecter: function() {
                axios.post(this.action, {
                        email: this.email,
                        password: this.password
                    })
                    .then(response => {
                        if(this.$parent.fromBasket) {
                            window.location.href= APP_URL + '/checkout?step=3';
                            return '';
                        }
                        window.location.href= response.request.responseURL
                    })
                    .catch(error => {
                        this.errors = [];
                        if( error.response ) {
                            for (const [key, value] of Object.entries(error.response.data.errors)) {
                                this.errors.push( {
                                    cle: key,
                                    value: value[0]
                                })
                            }
                        }
                    })
                    .finally(() =>  {
                        this.$parent.getNewToken();
                    })
            }

        },
    }
</script>
