<template>

</template>

<script>
import {mapMutations} from 'vuex'

export default {
    mounted() {
        this.emptyBasket();
    },
    methods: {
        ...mapMutations([
            'emptyBasket'
        ]),

    },
}
</script>