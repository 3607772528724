<template>
    <section id="coworking-results">
        <div v-if="isLoading" class="loading-icons">
            <img :src="urlLoadingIcon">
        </div>
        <div class="cr-container">
            <template v-for="(result, index) in results">
                <div class="cr-card box-shadow-2">
                    <div class="cr-photo">
                        <a :href="getUrlDescription(result)">
                            <img class="cr-photo-img" :src="getUrlSrcPhoto(result)" :alt="result.nom">
                        </a>
                    </div>
                    <div class="cr-content">
                        <a class="crc-a-title" :href="getUrlDescription(result)">
                            <h2 class="crc-title">
                                {{ result.nom }}
                            </h2>
                        </a>
                        <p class="text-helper">
                            {{ result.description }}
                        </p>
                        <div class="hr"></div>
                        <div v-if="result.type_espace_id == 1">
                            <div class="crc-icons-text">
                                <p class="crc-icons-text"><i class="fas fa-wifi crc-icons"></i> Fibre / Wifi gratuit</p>
                                <p class="crc-icons-text"><i class="fas fa-door-closed crc-icons"></i> Casier fermé</p>
                                <p class="crc-icons-text"><i class="fas fa-envelope crc-icons" ></i> Réception gratuite du courrier</p>
                            </div>
                        </div>
                        <div v-else>
                            <div class="crc-icons-text">
                                <p class="crc-icons-text"><i class="fas fa-wifi crc-icons"></i> Fibre / Wifi gratuit</p>
                                <p class="crc-icons-text"><i class="fas fa-user crc-icons"></i> 10 personnes</p>
                                <p class="crc-icons-text"><i class="fas fa-chalkboard-teacher crc-icons"></i> Vidéoprojecteur</p>
                            </div>
                        </div>
                        <div class="hr"></div>
                        <div class="crc-horaire-price">
                            <div class="text-helper">
                                <span> {{ 'Du ' + $moment(result.date_debut).format('DD/MM/YYYY') + ' au ' +  $moment(result.date_fin).format('DD/MM/YYYY')}}</span><br>
                                <span> {{ result.horaire}}</span><br>
                            </div>
                            <div class="crc-price"> {{ result.prix }} €
                                <span class="text-helper"> /{{ result.duree}}</span>
                            </div>
                        </div>
                        <br>
                        <div class="crc-button">
                            <button @click="addToBasket(result)" type="button" class="btn btn-green btn-ajouter-panier">Réserver</button>
                        </div>
                    </div>
                </div>
            </template>
            <div v-if="!results.length">
                <p class="cr-p-no-result">Aucun résultat trouvé</p>
            </div>
        </div>
    </section>
</template>

<script>
    import {mapMutations} from "vuex";

    export default {
        inject: ['$moment','addToBasket'],
        mounted() {
        },
        data () {
            return {
                urlHome: APP_URL,
                urlBureauDeCoworking: APP_URL + '/bureau-de-coworking',
                urlSalleDeReunion: APP_URL + '/salle-de-reunion',
                urlPhotos: APP_URL + '/assets/images/',
                urlLoadingIcon: APP_URL + '/assets/icons/loading-pink.png',
                csrf: document.head.querySelector('meta[name="csrf-token"]').content,
                isLoading: false,
                results: {},
            }
        },
        methods: {
            getUrlDescription(result){
                let url = '';
                if(result.type_espace_id == 1) {
                    url = APP_URL + '/bureau-de-coworking?espace=' + result.id +
                    '&type_espace=' + result.type_espace_id + '&date=' + result.date_debut + '&duree=' + result.duree_espace_id
                    + '&name=' + result.nom
                }
                else {
                    url = APP_URL + '/salle-de-reunion?espace=' + result.id +
                    '&type_espace=' + result.type_espace_id + '&date=' + result.date_debut + '&duree=' + result.duree_espace_id
                    + '&name=' + result.nom
                }

                return url;
            },
            getUrlSrcPhoto(result) {
                return this.urlPhotos + result.image.split(';')[0];
            }
        },
    }
</script>
