export const config = {
    globalProperties: {
        filters: () => ({
            capitalize(value) {
                if (!value) return ''
                value = value.toString()
                return value.charAt(0).toUpperCase() + value.slice(1)
            }
        }),
        functions: () => ({
            getStatusReservationColor(value) {
                if( value > 0 ) {
                    switch (value) {
                        case 1:
                            return '#0066c0'
                            break;
                        case 2:
                            return '#1ca27e'
                            break;
                        case 3:
                            return 'red'
                            break;
                    }
                }
                return '';
            },
            getStatusLibelle(value) {
                if( value > 0 ) {
                    switch (value) {
                        case 1:
                            return 'en attente de paiement'
                            break;
                        case 2:
                            return 'validée'
                            break;
                        case 3:
                            return 'annulée'
                            break;
                    }
                }
                return '';
            },
            isValidTelephone(value) {
                let regxTel = /^(\+33|0|0033)[0-9]{9}$/;
                return regxTel.test(value)
            },
            isValidCP(value) {
                let regxCP = /^(([0-8][0-9])|(9[0-5]))[0-9]{3}$/;
                return regxCP.test(value)
            },
            isValidEmail(value) {
                let regxEmail = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
                return regxEmail.test(value)
            },
            isEmpty(value) {
                if (value.trim() === null || value.trim() === "")
                    return true;

                return false;
            },
        }),
    },
}