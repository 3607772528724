<template>
    <section id="mes-reservations">
        <div class="mes-reservations-container">
            <div class="mes-reservation-content">
                <h1>Mes Réservations</h1>
                <div class="mes-reservations-box box-shadow">
                    <label>Choisissez votre réservation:</label>
                    <select class="select-input" v-model="maReservationSelectedId" @change="getReservation()">
                        <option v-for="maReservation in mesReservations" :value="maReservation.id">
                            {{ $moment(maReservation.created_at).format('DD/MM/YYYY  HH:mm') }}
                            - {{ maReservation.reservation_numero  }}
                            - {{ maReservation.prix_total  }} €
                        </option>
                    </select>
                    <div class="hr"></div>
                    <p v-show="maReservation.id" class="bold">Contenu de la réservation:</p>
                    <div v-for="(monArticle, index) in maReservation['reservation_article']" >
                        <div class="item-container box-shadow">
                            <div class="item-image">
                                <img class="item-img" :src="urlPhotos + monArticle.espace.image.split(';')[0]" alt="photos-bureau-salles-reu">
                            </div>
                            <div class="item-decription">
                                <h3>{{monArticle.espace.nom}}</h3>
                                <div class="item-description-line-container">
                                    <p class="bold">Date:</p>
                                    <p>Du {{ $moment(monArticle.date_debut).format('DD/MM/YYYY') }} au {{ $moment(monArticle.date_fin).format('DD/MM/YYYY') }}</p>
                                </div>
                                <div class="item-description-line-container">
                                    <p class="bold">Durée:</p>
                                    <p>{{ monArticle.duree_espace.duree }}</p>
                                </div>
                                <div class="item-description-line-container">
                                    <p class="bold">Horaire:</p>
                                    <p>{{ monArticle.duree_espace.horaire }}</p>
                                </div>
                                <div class="item-description-line-container">
                                    <p class="bold">Prix:</p>
                                    <p class="p-item-prix bold">{{ monArticle.prix }}€</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div v-if="maReservation.id" class="resume">
                <h1>En résumé</h1>
                <div class="resume-container box-shadow">
                    <div class="item-resume-container">
                        <p>Référence:  </p>
                        <p class="bold">{{ maReservation.reservation_numero }}</p>
                    </div>
                    <div class="item-resume-container">
                        <p>Status:  </p>
                        <p :style="{ color: $functions.getStatusReservationColor(maReservation.status_id)}" class="bold">{{ $filters.capitalize(maReservation.reservation_status.status) }}</p>
                    </div>
                    <div class="item-resume-container">
                        <p class="bold">Total:  </p>
                        <p class="bold montant-price-total">{{ maReservation.prix_total }}€</p>
                    </div>
                    <div class="hr"></div>
                    <div class="resume-buttons">
                        <a class="btn btn-yellow btn-resume" :href="urlHome">Continuer mes achats</a>
                        <a v-if="maReservation.stripe_recept_url" class="btn btn-green btn-resume" @click="showFacture()">Voir facture</a>
                        <div class="hr"></div>
                        <a v-if="cancellable" @click="cancelReservation()" class="btn btn-pink btn-resume">Annuler</a>
                    </div>
                </div>
            </div>
        </div>
    </section>

</template>

<script>
export default {
    props: ['mesReservations'],
    inject: ['$moment', '$swal2'],
    mounted() {
    },
    data () {
        return {
            urlHome: APP_URL,
            urlPhotos: APP_URL + '/assets/images/',
            maReservationSelectedId: '',
            maReservation: {},
            cancellable: false
        }
    },
    methods: {
        getReservation () {
            if(this.maReservationSelectedId > 0) {
                axios.post(APP_URL + '/api/reservation/detail-reservation', {
                    idReservation: this.maReservationSelectedId,
                })
                .then(response => {
                    this.cancellable = false;
                    this.maReservation = response.data.reservation;

                    if(this.maReservation.stripe_paiement_status == 'paid' && response.data.cancellable && this.maReservation.status_id == '2') {
                        this.cancellable = true;
                    }
                })
                .catch(error => {
                    alert(error);
                })
                .finally(() =>  {
                })
            }
        },
        showFacture() {
            if(this.maReservation.stripe_recept_url) {
                window.open(this.maReservation.stripe_recept_url,'_blank');
            }
        },
        cancelReservation() {
            if(this.cancellable) {
                this.$swal2.fire({
                    title: 'Êtes vous sûre de vouloir annuler cette réservation ?',
                    html: "Un montant total de <span class = 'bold'>" +
                        this.maReservation.prix_total + "€</span> vous sera remboursé si vous confirmez.",
                    icon: 'warning',
                    showCancelButton: () => true,
                    confirmButtonColor: 'var(--main-red-color)',
                    cancelButtonColor: 'var(--main-green-color)',
                    iconColor: 'var(--main-red-color)',
                    confirmButtonText: 'J\'annule ma réservation',
                    cancelButtonText: 'Ne pas annuler',
                    reverseButtons: true,
                    focusCancel: true,
                    showLoaderOnConfirm: true,
                    backdrop: true,
                    preConfirm: (login) => {
                        this.$swal2.fire({
                            title: 'Annulation en cours',
                            icon: 'info',
                            showCloseButton: false,
                            showCancelButton: false,
                            iconColor: 'var(--main-green-color)',
                            allowOutsideClick: false,
                            allowEscapeKey: false,
                            allowEnterKey: false
                        })
                        this.$swal2.showLoading();

                        return axios.post(APP_URL + '/api/reservation/annuler-reservation', {
                                idReservation: this.maReservationSelectedId,
                            })
                            .then(response => {
                                if(response.data.success == false) {
                                    throw new Error(response.data.message)
                                }

                                return response
                            })
                            .catch(error => {
                                this.$swal2.showValidationMessage(
                                    `Erreur: ${error}`
                                )
                            })
                            .finally(() =>  {
                                this.$swal2.hideLoading();
                            })
                    },
                    }).then((result) => {
                        if (result.isConfirmed) {
                            this.maReservation= {};
                            this.maReservationSelectedId = '';
                            this.cancellable = false;

                            this.$swal2.fire({
                                title:'Réservation annulée !',
                                html:'Votre réservation a bien été annulée, vous serez bientôt remboursé.',
                                icon:'success',
                            })
                            this.getReservation();

                        } else {
                            this.$swal2.fire({
                                html:'La réservation n\'a pas été annulée.',
                                icon:'info',
                                iconColor: 'var(--main-green-color)',
                            })
                        }
                })
            }
        }
    }
}
</script>
