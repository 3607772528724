<template>
    <header-home ref="HeaderHomeRef"></header-home>
    <header-checkout ref="HeaderCheckoutRef"></header-checkout>
    <panier-checkout v-show="currentStep == 1" ref="PanierCheckoutRef"></panier-checkout>
    <commande-informations v-show="currentStep == 3" ref="DonneeCommandeRef"></commande-informations>
    <form class="d-none" id="createCheckout" :action="urlPaiement" method="POST">
        <input type="hidden" name="_token" :value="csrf" />
        <input type="hidden" name="id">
        <button type="submit" id="checkout-button">Checkout</button>
    </form>
</template>

<script>
import HeaderHome from "../all/HeaderHome";
import HeaderCheckout from "./HeaderCheckout";
import PanierCheckout from "./PanierCheckout";
import CommandeInformations from "./CommandeInformations";
import {mapGetters, mapState, mapMutations, mapActions} from 'vuex';

export default {
    mounted() {
        this.currentStep = parseInt(this.step);

    },
    computed: {
        //Ajout des getters du store
        ...mapGetters([
        ]),
        ...mapState({
            //helper to found the variable count from the state
            userState: state => state.user,
            userFacturation: state => state.userFacturation,
            basket: state => state.basket,
            basketTotalPrice: state => state.basketTotalPrice
        })
    },
    components:{
        HeaderHome,
        HeaderCheckout,
        PanierCheckout,
        CommandeInformations,
    },
    inject: ['$swal2'],
    props: ['auth', 'step'],
    data () {
        return {
            urlHome: APP_URL,
            urlPaiement: APP_URL + '/paiement/paiement-send-form',
            currentStep: 1,
            disabledValidButton: false,
            csrf: document.querySelector('meta[name="csrf-token"]').getAttribute('content')
        }
    },
    provide: function () {
        return {
            nextStep: this.nextStep,
            getCurrentStep: this.getCurrentStep,
            setCurrentStep: this.setCurrentStep,
            getDisabledValid: this.getDisabledValid,
        }
    },
    methods: {
        ...mapMutations([
            'increment', // map `this.incrementAsync()` to `this.$store.commit('incrementAsync')`
            'addBasketItem',
            'emptyBasket'
        ]),
        ...mapActions([
            'incrementAsync', // map `this.increment()` to `this.$store.dispatch('increment')`
        ]),
        nextStep() {
            switch (this.currentStep) {
                case 1:
                    //Si un article au pannier
                    if(this.basket.length) {
                        let html = '<div style="text-align: left"><p class="bold"> Il vous sera demandé de signer dans le formulaire un engagement moral à rendre un service aux bénéficiaires de l’association ARS95 ou à participer à la vie de la Canopée. </p>' +
                            '<p>Le service peut prendre diverses formes : </p>' +
                            '<p>- donner de son temps pour aider des demandeurs d’emploi dans leurs démarches </p>' +
                            '<p>- participer à la vente des vêtements de COUSU MAIN sur le marché nocturne d’Argenteuil le mercredi soir de 17H à 20H </p>' +
                            '<p>- partager un contact dans une entreprise en vue d’élargir le réseau des conseillères professionnelles / partager un contact utile pour l’association ARS95 </p>' +
                            '<p>- présenter son métier aux demandeurs d’emploi à l’occasion d’un petit-déjeuner</p>' +
                            '<p>- aider à l’animation d’un afterwork (après 17H)</p>' +
                            '<p>- aider à la préparation d’un repas en commun</p>' +
                            '<p>- adhérer à l’association ARS95, voire devenir administrateur </p></div>';

                        this.$swal2.fire({
                            title: 'Contrepartie en service',
                            html: html,
                            icon: 'info',
                            width: '95%',
                            confirmButtonText: 'J\'accepte',
                            cancelButtonText: 'Je refuse',
                            showCloseButton: false,
                            allowOutsideClick: false,
                            allowEscapeKey: false,
                            allowEnterKey: false,
                            showCancelButton: true,
                            iconColor: 'var(--main-green-color)',
                        }).then((result) => {
                            if (result.isConfirmed) {
                                //Se connecter
                                if (this.userState) {
                                    this.currentStep = 3
                                    //window.scrollTo(0, 0);
                                    document.getElementById('navigation').scrollIntoView();
                                    return ''
                                }
                                //Se connecter
                                window.location.href = APP_URL + '/account/signin?from=basket';
                            }

                            else {
                                window.location.href = APP_URL;
                            }
                        })

                    }
                    break;
                case 3:
                    let step3Ref = this.$refs.DonneeCommandeRef;

                    if(step3Ref.isDataFormValid() && this.basket.length && this.userState) {
                        this.disabledValidButton = true;
                        axios.post(APP_URL + '/api/reservation/creer-reservation', {
                            userFacturation: this.userFacturation,
                            basket: this.basket,
                            totalPrice: this.basketTotalPrice
                        })
                        .then(response => {
                            let idReservation = response.data.reservation.id;
                            document.querySelector("form[id='createCheckout'] input[name='id']").value = idReservation;
                            document.forms["createCheckout"].submit();
                        })
                        .catch(error => {
                            this.disabledValidButton = false;
                            this.emptyBasket();
                            this.$swal2.fire({
                                title: "Erreur !",
                                text: error.response.data.message,
                                icon: "error",
                            })
                        })
                        .finally(() => {


                        })
                    }
                    break;

            }
        },
        getCurrentStep() {
            return this.currentStep;
        },
        setCurrentStep(step) {
            this.currentStep = step;
        },
        getDisabledValid() {
            return this.disabledValidButton;
        },


    },
}
</script>
