<template>
    <div class="account-box-container">
        <div id="forget-password">
            <div class="account-box-one">
                <h1 class="account-h1">Mot de passe oublié ?</h1>
                <div class="container-errors" v-if="errors.length">
                    <li v-for="error in errors">
                        {{ error.value }}
                    </li>
                </div>
                <p class="text-helper text-center">Saisissez l'adresse mail associée à votre compte.
                    Nous allons envoyer à cette adresse un lien vous permettant de réinitialiser facilement votre
                    mot de passe.</p>
                <form method="POST">
                    <label for="email" class="account-label">Adresse e-mail</label>
                    <input type="text" id="email" name="email" v-model="email" class="account-input">
                    <button @click.prevent="passwordForget" class="btn btn-pink btn-account">
                        Valider
                    </button>
                </form>
                <a href="#" @click="showParentComponent('se-connecter')" class="signin-a-back-login">
                    <p class="signin-p-back-login bold">
                        Retour à la connexion
                    </p>
                </a>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        mounted() {
            console.log('PasswordForget mounted.')
        },
        props: [],
        data () {
            return {
                //action: APP_URL + '/password/email',
                action: APP_URL + '/account/reset-password-with-token',
                email: '',
                errors: [],
            }
        },
        methods: {
            showParentComponent: function (component) {
                if(typeof this.$parent.showComponent !== 'undefined' && typeof this.$parent.showComponent == 'function') {
                    this.$parent.showComponent(component)
                }
            },
            passwordForget: function() {
                axios.post(this.action, {
                    email: this.email
                })
                .then(response => {
                    //console.log(response)
                    this.$parent.emailForget = this.email;
                    this.showParentComponent("password-forget-confirmation")
                    //console.log(this.$laravel)
                })
                .catch(error => {
                    this.errors = [];
                    if( error.response ) {
                        console.log(error.response)
                        for (const [key, value] of Object.entries(error.response.data.errors)) {
                            this.errors.push( {
                                cle: key,
                                value: value[0]
                            })
                        }
                    }
                })
                .finally(() =>  {
                    this.$parent.getNewToken();
                })
            }
        },
    }
</script>
