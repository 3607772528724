<template>
    <div class="bd-filters">
        <div class="bdf-inputs-container">
            <div class="bdf-form-group">
                <label class="bdf-label bold" for="date">Date</label>
                <v-date-picker class="d-inline rechercher-dp" :model-config="modelConfig"
                               :masks="masks" v-model="sdate" color="pink" :min-date="$moment().add(1, 'days').format('YYYY-MM-DD')"
                               :disabled-dates='{ weekdays: [1, 7] }' is-required>
                    <template v-slot="{ inputValue, inputEvents }">
                        <i class="fas fa-calendar icon-rechercher"></i>
                        <input
                            placeholder="Date"
                            id="date"
                            class="bdf-input"
                            :value="$moment(sdate).format('DD/MM/YYYY')"
                            v-on="inputEvents"
                            readonly
                        />
                    </template>
                </v-date-picker>
            </div>
            <div class="bdf-form-group">
                <label class="bdf-label bold">Durée</label>
                <select class="bdf-select" @change="updateDateValue()" v-model="dureeEspaceSelected">
                    <option v-for="option in typeEspaceSelected==1 ? dureeEspaceBureau: dureeEspaceSR" :value="option.value">
                        {{ option.text }}
                    </option>
                </select>
            </div>
        </div>
        <div class="loading-icons-div" v-if="isLoading">
            <img :src="urlLoadingIcon" class="loading-icons-img">
        </div>
        <div v-if="isEspaceAvailable">
            <div class="bdf-button">
                <a @click="addToBasket()" class="btn btn-green btn-ajouter-panier">Réserver</a>
            </div>
            <p class="bdf-p-helper">Aucun montant ne vous sera débité pour le <br>moment</p>
            <div class="bdf-recap">
                <div class="bdf-intituler">
                    <span> Horaire</span>
                </div>
                <div class="bdf-result">
                    {{ horaire }}
                </div>
            </div>
            <div class="bdf-recap">
                <div class="bdf-intituler">
                    <span> {{ tarifLibelle }}</span>
                </div>
                <div class="bdf-result">
                    {{ price }}€
                </div>
            </div>
            <div class="bdf-recap">
                <div class="bdf-intituler">
                    <span> Frais de réservation</span>
                </div>
                <div class="bdf-result">
                    0€
                </div>
            </div>
            <div class="hr"></div>
            <div class="bdf-recap">
                <span class="bold">
                    Total
                </span>
                <span class="bold bdf-price-total">
                    {{ price }}€
                </span>
            </div>
        </div>
        <div class="text-center" v-else>
            <p class="espace-indisponible">L'espace n'est pas disponible à cette date.<br> Merci de choisir une autre date.</p>
        </div>

    </div>
</template>

<script>
    import {mapGetters, mapState, mapMutations, mapActions} from 'vuex'
    export default {
        props: ['pageData'],
        inject: ['$moment', '$swal2'],
        computed: {
            //Ajout des getters du store
            ...mapGetters([
                'getItemBasketCount',
            ]),
            ...mapState({
                maxBasketItem: state => state.maxBasketItem,
            }),
            sdate: {
                get: function () {
                    return this.selectedDate;
                },
                set: function (newValue) {
                    let date = this.$moment(newValue);
                    let day = date.get('date');

                    if(day !== 1 && this.dureeEspaceSelected === 5) {
                        date.add(1, 'months');
                        date.set('date', 1);
                    }

                    this.selectedDate = date.format('YYYY-MM-DD');
                    this.rechercher();
                }
            }
        },
        mounted() {
            let pageDataObject = JSON.parse(this.pageData);
            this.typeEspaceSelected = parseInt(pageDataObject.type_espace_id);
            this.dureeEspaceSelected = parseInt(pageDataObject.duree);
            this.selectedDate = pageDataObject.date_debut;
            this.espaceIdSelected = parseInt(pageDataObject.espace_id);
            this.rechercher()
        },
        components:{
        },
        data () {
            return {
                urlHome: APP_URL,
                urlLoadingIcon: APP_URL + '/assets/icons/loading-pink.png',
                csrf: document.head.querySelector('meta[name="csrf-token"]').content,
                isLoading: true,
                item: {},
                typeEspaceSelected: null,
                dureeEspaceSelected: null,
                selectedDate: null,
                espaceIdSelected: null,
                isEspaceAvailable: false,
                horaire: '',
                tarifLibelle: '',
                price: '',
                modelConfig: {
                    type: 'string',
                    //masque de la variable vue
                    mask: 'YYYY-MM-DD', // Uses 'iso' if missing
                },
                masks: {
                    //à l'affichage
                    input: 'DD/MM/YYYY',
                },
                dureeEspaceBureau: [
                    { text: 'Matin', value: 1 },
                    { text: 'Après midi', value: 2 },
                    { text: 'Journée', value: 3 },
                    { text: 'Semaine', value: 4 },
                    { text: 'Mois', value: 5 },
                ],
                dureeEspaceSR: [
                    { text: 'Matin', value: 1 },
                    { text: 'Après midi', value: 2 },
                ],
            }
        },
        methods: {
            ...mapMutations([
                'addBasketItem',
            ]),
            addToBasket() {
                if(this.item) {
                    let HeaderHome = this.$root.$refs.HeaderHomeRef;
                    let initialCount = this.getItemBasketCount;

                    if (initialCount == this.maxBasketItem) {
                        this.$swal2.fire({
                            title: "Erreur !",
                            text: "Le nombre maximum d'articles dans le panier a été atteint",
                            icon: "error",
                        })
                        return
                    }

                    this.addBasketItem(this.item);
                    if (initialCount < this.getItemBasketCount) {
                        HeaderHome.openPopup('popup-basket')
                    } else {
                        this.$swal2.fire({
                            title: "Erreur !",
                            text: "Le produit est déjà dans votre panier",
                            icon: "error",
                        })
                    }
                }
            },
            rechercher() {
                if(!this.selectedDate || !this.dureeEspaceSelected || !this.typeEspaceSelected || !this.espaceIdSelected )
                    return false;

                this.isLoading = true;
                this.isEspaceAvailable = false;
                axios.post(APP_URL + '/api/availability/check-date-availability-unique', {
                    dateReservation: this.selectedDate,
                    dureeEspace: this.dureeEspaceSelected,
                    typeEspace: this.typeEspaceSelected,
                    idEspace: this.espaceIdSelected
                    })
                    .then(response => {
                        if(response.data.success && response.data.available) {
                            this.item = response.data.espace[0],
                            this.isEspaceAvailable = true;
                            this.horaire = response.data.espace[0].horaire;
                            this.tarifLibelle = 'Tarif ' + response.data.espace[0].duree;
                            this.price = response.data.espace[0].prix;
                        }
                    })
                    .catch(error => {
                        this.$swal2.fire({
                            title: "Erreur !",
                            text: "Une erreur s'est produite. Merci de recommencer votre recherche à nouveau.",
                            icon: "error",
                        })
                        return
                    })
                    .finally(() =>  {
                        this.isLoading = false;
                        //coworkingResults.isLoading = false;

                    })
            },
            updateDateValue() {
                this.sdate = this.selectedDate;
            },
        },
    }
</script>
