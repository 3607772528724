<template>
    <div class="header-checkout">
        <ul class="menu-checkout-container">
            <li>
                <a class="bold" :class="{'li-checkout-active': getCurrentStep() == 1}" @click="setCurrentStep(1)">1. <span class="li-checkout-span-text">Panier</span></a>
            </li>
            <li>
                <a class="bold" :class="{'li-checkout-active': getCurrentStep() == 2}" @click="">2.  <span class="li-checkout-span-text">Identification</span></a>
            </li>
            <li>
                <a class="bold" :class="{'li-checkout-active': getCurrentStep() == 3}" @click="getCurrentStep() > 3 ?  setCurrentStep(3) : ''" >3. <span class="li-checkout-span-text">Informations commande</span></a>
            </li>
            <li>
                <a class="bold" :class="{'li-checkout-active': getCurrentStep() == 4}" @click="">4. <span class="li-checkout-span-text">Paiement</span></a>
            </li>
        </ul>
    </div>
</template>

<script>

    export default {
        inject: ['$moment', 'getCurrentStep', 'setCurrentStep'],
        mounted() {

        },
        computed: {

        },
        components:{
        },
        props: ['auth'],
        data () {
            return {
                urlHome: APP_URL,
            }
        },
        methods: {


        },
    }
</script>