<template>
    <section id="signin">
        <div class="account-container">
            <se-connecter v-show="currentComponent == 'se-connecter'"></se-connecter>
            <password-forget v-show="currentComponent == 'password-forget'"></password-forget>
            <sinscrire v-show="currentComponent == 'sinscrire'"></sinscrire>
            <password-forget-confirmation v-show="currentComponent == 'password-forget-confirmation'">
            </password-forget-confirmation>
            <password-reset v-show="currentComponent == 'password-reset'"></password-reset>
        </div>
    </section>
</template>

<script>
    import SeConnecter from "./signin/SeConnecter";
    import PasswordForget from "./signin/PasswordForget";
    import Sinscrire from "./signin/Sinscrire";
    import PasswordForgetConfirmation from "./signin/PasswordForgetConfirmation";
    import PasswordReset from "./signin/PasswordReset";

    export default {
        mounted() {
            console.log('Sigin mounted.')
        },
        components:{
            SeConnecter,
            PasswordForget,
            Sinscrire,
            PasswordForgetConfirmation,
            PasswordReset
        },
        props: ['loadComponent', 'emailReset', 'tokenReset', 'fromBasket'],
        data () {
            return {
                actionRefreshToken: APP_URL + '/refresh-token',
                emailForget: '',
                currentComponent: this.loadComponent,
            }
        },
        methods: {
            showComponent: function (component) {
                this.currentComponent = component
            },
            accueil: function (component) {
                window.location.href = APP_URL
            },
            getNewToken: function () {
                    axios({
                        method: 'get',
                        url: this.actionRefreshToken,
                    })
                    .then(response => {
                        if (response.data.token != null) {
                            document.querySelector('meta[name="csrf-token"]').setAttribute('content', response.data.token);
                            window.Laravel.csrfToken = response.data.token;
                            window.axios.defaults.headers.common["X-CSRF-TOKEN"] = response.data.token;
                        }
                    })
                    .catch(error => {
                        //console.log(error);
                    })
                    .finally(() =>  {

                    })
            }
        }

    }
</script>
