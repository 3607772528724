<template>
    <div class="account-box-container big">
        <div id="registration">
            <div class="account-box-one">
                <h1 class="account-h1">Nouveau client ? </h1>
                <div class="container-errors" v-if="errors.length">
                    <li v-for="error in errors">
                        {{ error.value }}
                    </li>
                </div>
                <form method="POST">
                    <div class="form-container">
                        <div class="form-group-two first-input">
                            <label for="name" class="account-label">Nom</label>
                            <input type="text" id="name" name="name" v-model="name" class="account-input">
                        </div>
                        <div class="form-group-two second-input">
                            <label for="prenom" class="account-label">Prénom</label>
                            <input type="text" id="prenom" name="prenom" v-model="prenom" class="account-input">
                        </div>
                        <div class="form-group-two first-input">
                            <label for="email" class="account-label">Adresse e-mail</label>
                            <input type="text" id="email" name="email" v-model="email" class="account-input">
                        </div>
                        <div class="form-group-two second-input">
                            <label for="telephone" class="account-label">Téléphone</label>
                            <input type="tel" id="telephone" name="telephone" v-model="telephone" class="account-input">
                        </div>
                        <div class="form-group-one">
                            <label for="password" class="account-label">Mot de passe</label>
                            <input type="password" name="password" id="password" v-model="password"
                                   class="account-input">
                        </div>
                        <div class="form-group-one">
                            <label for="password_confirmation" class="account-label">Confirmer mot de passe</label>
                            <input type="password" name="password_confirmation" id="password_confirmation" v-model="password_confirmation"
                                   class="account-input">
                        </div>
                        <button @click.prevent="sinscrire" class="btn btn-pink btn-account">
                            Créer mon compte
                        </button>
                    </div>
                </form>
            </div>
            <div class="account-box-two">
                <h1 class="account-h1">Déjà client ?</h1>
                <a @click="showParentComponent('se-connecter')" class="btn btn-pink btn-account">Se connecter</a>
                <p class="text-helper text-center">En créant ou en vous connectant à un compte, vous acceptez nos
                    conditions générales et notre charte de confidentialité.</p>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        mounted() {
            console.log('Sinscrire mounted.')
        },
        props: [],
        data () {
            return {
                action: APP_URL + '/register',
                name: '',
                prenom: '',
                email: '',
                telephone: '',
                password: '',
                password_confirmation: '',
                errors: []
            }
        },
        methods: {
            showParentComponent: function (component) {
                if(typeof this.$parent.showComponent !== 'undefined' && typeof this.$parent.showComponent == 'function') {
                    this.$parent.showComponent(component)
                }
            },
            sinscrire:function() {
                axios.post(this.action, {
                        name: this.name,
                        prenom: this.prenom,
                        email: this.email,
                        telephone: this.telephone,
                        password: this.password,
                        password_confirmation: this.password_confirmation
                    })
                    .then(response => {
                        if(this.$parent.fromBasket) {
                            window.location.href= APP_URL + '/checkout?step=3';
                            return '';
                        }
                        window.location.href= response.request.responseURL
                    })
                    .catch(error => {
                        this.errors = [];
                        if( error.response ) {
                            for (const [key, value] of Object.entries(error.response.data.errors)) {
                                this.errors.push( {
                                    cle: key,
                                    value: value[0]
                                })
                            }
                        }
                    })
                    .finally(() =>  {
                        this.$parent.getNewToken();
                    })
            }

        },
    }
</script>
